import * as React from 'react';

const defaultDelay = 500;

export function useDebounceValue<T>(value: T, delay: number = defaultDelay): T {
	const [debouncedValue, setDebouncedValue] = React.useState<T>(value);
	React.useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay);
		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);
	return debouncedValue;
}

function debounce<T extends (...args: any) => ReturnType<T>>(fn: T, delay: number) {
	let timer: NodeJS.Timeout;
	return (...args: Parameters<T>) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, delay);
	};
}

export function useDebounceCallback<T extends (...args: any) => ReturnType<T>>(
	callback: T,
	delay: number = defaultDelay
) {
	const callbackRef = React.useRef(callback);

	React.useLayoutEffect(() => {
		callbackRef.current = callback;
	});

	return React.useMemo(() => debounce((...args: Parameters<T>) => callbackRef.current(...args), delay), [delay]);
}
